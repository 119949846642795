import * as React from 'react'
import styled from 'styled-components'
import * as t from './types'
import { ms } from 'modules/browser/const'
import ty from 'utils/typographie'
import ArrowSvg from 'svg/Arrow'
import Link from 'theme/atoms/Link'
import useLazyImageSrc from 'hooks/useLazyImageSrc'
import useInView from 'hooks/useInView'
import * as actions from './actions'

export default function FullwidthHeroBanner(props: t.Props) {
  const [ref, image] = useLazyImageSrc(
    props.context.optImg,
    props.context.base64,
    100,
  )
  const [wrapperRef, inView] = useInView()
  const wasInView = React.useRef(false)

  React.useEffect(() => {
    if (inView && !wasInView.current) {
      wasInView.current = true
      props.eecTrack && actions.scrollIntoView(props)
    }
  }, [inView, props])

  const handleClick = () => props.eecTrack && actions.click(props)

  return (
    <Wrapper
      ref={wrapperRef}
      imgSrc={image}
      scaleBehaviour={props.imageScaleBehaviour}
      to={props.link}
      onClick={handleClick}
    >
      <div className="img full-width" ref={ref} />
      <div className="label">
        <h2>{props.label}</h2>
        {props.link && (
          <span>
            jetzt shoppen <ArrowSvg color="#262626" />
          </span>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(Link)`
  position: relative;

  > .img {
    height: 360px;
    background: url(${(p) => p.imgSrc}) no-repeat ${(p) => p.scaleBehaviour};

    background-size: cover;

    @media (min-width: ${ms.LAPTOP}px) {
      height: 500px;
    }

    @media (min-width: ${ms.LAPTOP_XL}px) {
      height: calc(100vh - 200px);
      max-height: 35vw;
    }
  }

  > .label {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    height: 85px;
    background: white;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${ms.LAPTOP}px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: -130px;
      padding: 15px 40px;
      height: 100px;
    }

    > h2 {
      ${ty([26, 0.46, 34], 'Cormorant Garamond', '500')}
      color: #262626;
      margin: 0;
      margin-bottom: 5px;

      @media (min-width: ${ms.LAPTOP_XL}px) {
        ${ty([32, 0.62, 46])}
      }
    }

    > span {
      ${ty([16, 0.51, 20], 'Cormorant Garamond', 'bold', 'italic')}
      @media (min-width: ${ms.LAPTOP_XL}px) {
        ${ty([18, 0.34, 26])}
      }
      color: #262626;
      > svg {
        margin-left: 5px;
      }
    }
  }
`
