import * as React from 'react'

type Props = {
  color?: string
}

export default function Arrow(props: Props) {
  let color = props.color || '#262626'
  return (
    <svg
      width="42px"
      height="9px"
      viewBox="0 0 42 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Line 4</title>
      <g id="shop-design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="desktop-home-wide-full"
          transform="translate(-882.000000, -1020.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Group-7" transform="translate(140.000000, 870.000000)">
            <g id="teaser-02" transform="translate(600.000000, 0.000000)">
              <g id="button" transform="translate(41.000000, 139.000000)">
                <path
                  id="Line-4"
                  d="M133.5,11 L142.5,15.5 L133.5,20 L133.5,16 L101.5,16 L101.5,15 L133.5,15 L133.5,11 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
